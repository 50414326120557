import { useEffect } from 'react'
import {
  initializeFaro as coreInit,
  FaroErrorBoundary,
} from '@grafana/faro-react'
import PropTypes from 'prop-types'
import { grafanaUtils } from 'shared/utils/Grafana'

type GrafanaFaroProps = {
  children: JSX.Element | JSX.Element[]
}

const GrafanaFaro = ({ children }: GrafanaFaroProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { enabledGrafanaFaro } = (window as any).grafana || {}
  useEffect(() => {
    const initializeFaro = () => {
      try {
        const initialOptions = grafanaUtils.getInitialOptions()
        coreInit(initialOptions)
      } catch (error) {
        console.error(error)
      }
    }

    if (enabledGrafanaFaro) {
      initializeFaro()
    }
  }, [])

  return <FaroErrorBoundary>{children}</FaroErrorBoundary>
}

GrafanaFaro.propTypes = {
  children: PropTypes.node.isRequired,
}
export default GrafanaFaro
