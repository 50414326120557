import axios from 'axios'
import { URLS_API } from './urls-api'
import { TeacherApiData } from './mappers/teacherToServiceData/teacherToServiceData'
import { RelativeApiData } from './mappers/relativeToServiceData/relativeToServiceData'
import { StudentAbove14ApiData } from './mappers/studentAboveFourteenToServiceData/studentAboveFourteenToServiceData'
import { StudentBelow14ApiData } from './mappers/studentBelowFourteenToServiceData/studentBelowFourteenToServiceData'
import { UserDataWithDefinedFlow } from 'shared/utils/types'

type EmailCheckResponse = {
  exists: boolean
}
export const getCheckEmail = async (email: string) => {
  const encodedEmail = encodeURIComponent(email)
  const url = `/bff-vle/users/checkEmail?email=${encodedEmail}`

  const { data } = await axios.get<EmailCheckResponse>(url)
  return data
}

export const getCheckUserName = async (userName: string) => {
  const url = `/bff-vle/users/checkUsername?username=${userName}`
  const { data } = await axios.get(url)
  return data
}

export const getCheckPasswordSecurity = async (password: string) => {
  const url = `/bff-vle/users/checkPasswordSecurity?password=${password}`
  const { data } = await axios.get(url)
  return data
}

export const getCheckNin = async (identifier: string, countryId: string) => {
  const url = `/bff-vle/users/checkNin?identifier=${identifier}&countryId=${countryId}`
  const { data } = await axios.get(url)
  return data
}

export const getTeacherPositions = async () => {
  const url = '/bff-vle/teachers/positions'
  const { data } = await axios.get(url)
  return data
}

export const getSchoolDepartments = async () => {
  const url = '/bff-vle/schools/departments'
  const { data } = await axios.get(url)
  return data
}

type ApiLevel = {
  id: string
  name: string
  order: number
}
export const getLevelsByCountry = async (
  countryId: string,
): Promise<ApiLevel[]> => {
  const url = `/bff-vle/countries/${countryId}/levels`
  const { data } = await axios.get(url)
  return data.levels || []
}

type ApiCourse = {
  id: string
  name: string
  order: number
  level: {
    id: string
    name: string
    order: number
  }
  age: string
  under14Age: boolean
}
export const getCoursesByStage = async (
  stageId: string,
): Promise<ApiCourse[]> => {
  const url = `/bff-vle/levels/${stageId}/courses`
  const { data } = await axios.get(url)
  return data?.courses || []
}
export const getSchoolsByCity = async (countryId: string) => {
  const url = `/bff-vle/cities/${countryId}/schools`
  const { data } = await axios.get(url)
  return data
}

export const postCreateUser = async (
  user: TeacherApiData | RelativeApiData | StudentAbove14ApiData,
): Promise<{
  id: string
}> => {
  const url = '/bff-vle/users/create'
  const { data } = await axios.post(url, user)
  return data
}

export const postCreateUserWithRelative = async (
  user: StudentBelow14ApiData,
): Promise<{
  relativeId: string
  studentId: string
}> => {
  const url = '/bff-vle/users/create/relativeWithStudentUnder'
  const { data } = await axios.post(url, user)
  return data
}

export const postResendEmail = async (
  email: UserDataWithDefinedFlow['email'],
) => {
  const { data } = await axios.post(URLS_API.RESEND_EMAIL, { email })
  return data
}
export const logout = () => {
  return axios.post(URLS_API.LOGOUT)
}

export const checkLogin = async () => {
  const { data } = await axios.get(URLS_API.CHECK_LOG_IN)
  return data
}
